<div class="main-header">

  <nav>
    <div class="left ">
      <button class="menu-toggler" (click)="setToggle()">
        <i class="iconify icon" data-icon="material-symbols-light:menu"></i>
      </button>
        <app-platform-switcher-button *ngIf="showPlatformSwitch"></app-platform-switcher-button>
    </div>
    <div class="right">
      <nav>
        <div class="header-top-section">
          <div class="header-top-right-container">
            <div class="ms-2">
              <button class="btn btn-outline-tertiary text-dark d-inline-flex align-items-center" type="button"
                      data-bs-toggle="dropdown" aria-expanded="false">
                <span class="iconify me-2 text-dark" data-icon="ci:user" style="font-size: 20px;"></span>
                {{ user?.email }}
                <span class="iconify text-dark" data-icon="ci:caret-down" style="font-size: 24px;"></span>
              </button>
              <ul class="dropdown-menu dropdown-menu-end user-dropdown-menu">
                  <li>
                      <a class="dropdown-item pointer" [routerLink]="'/dashboard/profile-management'">
                          <i class="iconify" data-inline="false" data-icon="iconamoon:profile"></i>
                          Profile Update</a>
                  </li>
                <li>
                  <a class="dropdown-item"
                     (click)="logout()"
                  >
                    <span class="iconify me-1 text-danger" data-icon="ci:log-out"
                          style="font-size: 18px;">

                    </span> Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </nav>

</div>
