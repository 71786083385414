<div
        [ngStyle]="{ left: toggled ? '0' : '-280px' }"
        class="side-nav side">
    <div class="d-flex logo justify-content-center align-items-center">
        <img [routerLink]="'/'" class="pointer" src="assets/img/cfs-logo.svg" alt="logo"/>
    </div>

    <div>
        <div class="navs">

            <div class="link-wrapper mb-3">
                <div class="link"
                     (click)="closeToggle()"
                     [routerLink]="['/dashboard']"
                     [class.router-link-active]="isDashboardRoute">
                    <i class="iconify" data-inline="false" data-icon="material-symbols:dashboard"></i>
                    <a href="javascript:void(0)">Dashboard</a>
                </div>
            </div>
            <div class="link-wrapper mb-3">
                <div class="link"
                     (click)="closeToggle()"
                     [routerLink]="['/dashboard/tickets']"
                     routerLinkActive="router-link-active">
                    <i class="iconify" data-inline="false" data-icon="solar:ticket-linear"></i>
                    <a href="javascript:void(0)">Tickets</a>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link" routerLinkActive="submenu-item-active"
                     data-bs-toggle="collapse" data-bs-target="#request-dropdown" aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="eos-icons:pull-request"></i>
                    <a href="javascript:void(0)">Requests</a>
                </div>
                <div class="collapse" id="request-dropdown">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-3">
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/requests/coding-request']">
                                <a href="javascript:void(0)">Coding & Implementation</a>
                            </div>

                        </li>
                    </ul>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link"
                     data-bs-toggle="collapse" data-bs-target="#master-dropdown" aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="mage:file-records"></i>

                    <a href="javascript:void(0)">Master Records</a>
                </div>
                <div class="collapse" id="master-dropdown">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-4">
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/platforms']">
                                <a href="javascript:void(0)">Platforms</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div
                                    class="submenu-item"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/master-records/customers']">
                                <a href="javascript:void(0)">Customer Value/LAG/C5</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/modules']">
                                <a href="javascript:void(0)">Modules</a>
                            </div>

                        </li>
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/production-units']">
                                <a href="javascript:void(0)">Production Units</a>
                            </div>

                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/business-req']">
                                <a href="javascript:void(0)">Business Requirements</a>
                            </div>

                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/lead-measure']">
                                <a href="javascript:void(0)">Lead Measures</a>
                            </div>

                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/lag-measure']">
                                <a href="javascript:void(0)">Lag Measures</a>
                            </div>

                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/execution/use-case']">
                                <a href="javascript:void(0)">Use Case</a>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link"
                     (click)="closeToggle()"
                     [routerLink]="['/dashboard/audit-trail']"
                     routerLinkActive="router-link-active">
                    <i class="iconify" data-inline="false" data-icon="ion:footsteps-outline"></i>
                    <a href="javascript:void(0)">Audit Trail</a>
                </div>
            </div>

            <div  class="link-wrapper mb-3">
                <div class="link"
                     data-bs-toggle="collapse" data-bs-target="#process-dropdown" aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="uil:process"></i>

                    <a href="javascript:void(0)">Process</a>
                </div>
                <div class="collapse" id="process-dropdown">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-4">
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/process/compliance-list']">
                                <a href="javascript:void(0)">Compliance</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/process/breach']">
                                <a href="javascript:void(0)">Breach</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/process/financial-cost']">
                                <a href="javascript:void(0)">Financial Report</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link"
                        data-bs-toggle="collapse" data-bs-target="#execution-dropdown" aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="mage:file-records"></i>

                    <a href="javascript:void(0)">Execution</a>
                </div>
                <div class="collapse" id="execution-dropdown">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-4">
                        <li class="my-3">
                            <div
                                    class="submenu-item"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/master-records/wig']">
                                <a href="javascript:void(0)">Wildly Important Goals (D1)</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div class="submenu-item"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/execution/commitment']">
                                <a href="javascript:void(0)">Lead Measure Commitments (D2)</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/execution/wig/list']">
                                <a href="javascript:void(0)">Scoreboards (D3)</a>
                            </div>
                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/execution/cadence']">
                                <a href="javascript:void(0)">Cadence (D4)</a>
                            </div>
                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/delegation']">
                                <a href="javascript:void(0)">Delegation</a>
                            </div>
                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/execution/player-stat']">
                                <a href="javascript:void(0)">Player Statistics</a>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link"
                     *ngIf="isAdmin()"
                     (click)="closeToggle()"
                     [routerLink]="['/dashboard/users']"
                     routerLinkActive="router-link-active">
                    <i class="iconify" data-inline="false" data-icon="solar:user-outline"></i>
                    <a href="javascript:void(0)">User Management</a>
                </div>
            </div>
        </div>
    </div>
</div>
