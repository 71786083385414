import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {User} from "../../models/user/user.model";
import {NavigationService} from "../../services/navigation.service";
import {filter, Subject} from "rxjs";

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
    user: User | undefined;
    toggled = false;
    showPlatformSwitch = false;
    private unsubscribeAll = new Subject<any>();

    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private navigationService: NavigationService) {
        this.activatedRoute.data.subscribe((data: any) => {

            if (data.showNav && window.innerWidth > 1024) {
                setTimeout(() => {
                    this.navigationService.changeToggleStatus(true);
                });
            }
        });

        this.navigationService.toggleStatus.asObservable().subscribe((status) => {
            this.toggled = status;
        });

    }

    login(): void {
        this.authenticationService.loginAndNavigateToDashboard();
    }

    logout(): void {
        this.authenticationService.logout(`${window.location.origin}`).subscribe();
    }

    private getShowPlatformSwitch(route: ActivatedRoute): boolean {
        let data = route.snapshot.data;
        if (data && data['showPlatformSwitch'] !== undefined) {
            return data['showPlatformSwitch'];
        }

        let showPlatformSwitch = false;

        for (const child of route.children) {
            data = child.snapshot.data;
            if (data && data['showPlatformSwitch'] !== undefined) {
                showPlatformSwitch = data['showPlatformSwitch'];
                return showPlatformSwitch;
            }
        }

        return showPlatformSwitch;

    }

    ngOnInit(): void {
        this.authenticationService.getUser().subscribe((user) => {
            if (user) {
                this.user = user;
            }
        });

        this.showPlatformSwitch = this.getShowPlatformSwitch(this.activatedRoute);
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.showPlatformSwitch = this.getShowPlatformSwitch(this.activatedRoute);
        });

    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }

    setToggle() {
        this.toggled = !this.toggled;
        this.navigationService.changeToggleStatus(this.toggled);
    }
}
