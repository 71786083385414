/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachment-dto';
import { PlayerCertificationDto } from './player-certification-dto';


export interface UpdateProfileDto { 
    firstName: string;
    lastName: string;
    profileImageDto?: AttachmentDto;
    signatureDto?: AttachmentDto;
    countryCode?: string;
    stateCode?: string;
    streetAddress?: string;
    designationShortName?: string;
    nextOfKinFirstName?: string;
    nextOfKinLastName?: string;
    nextOfKinEmailAddress?: string;
    nextOfKinPhoneNumber?: string;
    skillList?: Array<string>;
    playerCertificationDtoList?: Array<PlayerCertificationDto>;
}

