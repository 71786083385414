/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HardwareDeploymentEnvironmentPojo { 
    id?: number;
    type?: HardwareDeploymentEnvironmentPojo.TypeEnum;
    totalMemoryInByte?: number;
    totalStorageInByte?: number;
    cpuCore?: number;
}
export namespace HardwareDeploymentEnvironmentPojo {
    export type TypeEnum = 'PRODUCTION' | 'TEST' | 'DEVELOPMENT' | 'PRE_PRODUCTION';
    export const TypeEnum = {
        Production: 'PRODUCTION' as TypeEnum,
        Test: 'TEST' as TypeEnum,
        Development: 'DEVELOPMENT' as TypeEnum,
        PreProduction: 'PRE_PRODUCTION' as TypeEnum
    };
}


