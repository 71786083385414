import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  CustomerDto,
  NameCodePojo,
  NameIdPojo,
  Ticket, TicketAssignmentDto,
  TicketClassificationDto,
  TicketControllerService, TicketDetailPojo,
  TicketLifecyclePojo,
  TicketType,
} from '../../../../../../sdk/customer-fulfillment-api-sdk';
import { AuthenticationService } from '../../../../services/authentication.service';
import { HelperService } from '../../../../services/helper.service';
import { Utils } from '../../../../shared/utils/utils';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-manage-tickets',
  templateUrl: './manage-tickets.component.html',
  styleUrls: ['./manage-tickets.component.css'],
})
export class ManageTicketsComponent implements OnInit {
  form: FormGroup;
  editForm: FormGroup;
  assignForm: FormGroup;

  successMessage: string;
  showSuccessMessageTrigger = false;
  errorMessage: string;
  showErrorMessageTrigger = false;
  submitting: boolean;
  ticketId: number;
  ticket: Ticket;
  data!: Array<TicketLifecyclePojo>;
  customer: CustomerDto;
  edit: boolean;
  // ticketType: Utils.enumValues()
  ticketStatus = Utils.enumValues(TicketLifecyclePojo.LifecycleStatusEnum);
  ticketCategories: NameCodePojo[] = [];
  subTicketCategories: NameCodePojo[] = [];
  ticketTypes = Utils.enumValues(TicketClassificationDto.TypeConstantEnum);
  service: NameCodePojo[] = [];
  subServices: NameCodePojo[] = [];
  representatives: NameIdPojo[] = [];
  details: TicketDetailPojo;
  isUpdate = false;
  isAssignment = false;
  enable: boolean;
  @ViewChild('closeTicketEdit') closeTicketEdit!: ElementRef;

  constructor(
    private fb: FormBuilder,
    private ticketControllerService: TicketControllerService,
    private authenticationService: AuthenticationService,
    public helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      description: [''],
    });

    this.assignForm = this.fb.group({
      assign: [''],
    });
    this.form.disable();
    this.activatedRoute.params.subscribe({
      next: (param) => {
        this.ticketId = param['id'];
        this.getTicket(this.ticketId);
      }
    })
    this.getTicket(this.ticketId);
    this.initializeEditForm();
    this.ticketClassificationProperties();
    this.getRepresentatives();

    this.assignForm.get('assign').valueChanges.subscribe((value) => {
      if(value !== this.details?.assignedTo?.id){
        this.enable = true;
      } else {
        console.log(value !== this.details?.assignedTo?.id)
        this.enable = false;
      }

    })
  }

  getErrorMessage(): string {
    return this.errorMessage;
  }
  showErrorMessage(error: any, flag: boolean): void {
    this.errorMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
      flag = false;
    }, 20000);
  }
  showSuccessMessage(msg, flag: boolean): void {
    this.successMessage = msg;
    this.showSuccessMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showSuccessMessageTrigger = false;
      flag = false;
    }, 10000);
  }

  getSuccessMessage(): string {
    return this.successMessage;
  }

  private getTicket(ticketId: any): void {
    this.ticketControllerService.searchTickets({ id: ticketId }).subscribe({
      next: (data: any) => {
        this.ticket = data?.results[0];
        this.getTicketDetails(ticketId);
        this.form.get('description').patchValue(this.ticket.description);
        // if(this.ticket?.assignedTo !== null || this.ticket?.assignedTo !== undefined){
        //   this.assignForm.get('assign').patchValue((this.ticket?.assignedTo);
        // }
        this.getCustomerDetails(data?.results[0]?.customer?.id);
        this.getLifecycleStatus();
      },
      complete: () => {},
      error: (error: unknown) => {
        this.showErrorMessage(this.helperService.getError(error), true);
      },
    });
  }

  private getLifecycleStatus(): void {
    const identifier = this.ticket.identifier || '';
    this.ticketControllerService
      .getTicketStatus({ identifier: identifier })
      .subscribe(
        (data) => {
          this.data = data;
          console.log(this.data);
        },
        (error) => {
          this.showErrorMessage(
            this.helperService.getError(error.error.message), true
          );
        }
      );
  }

  getCustomerDetails(id: number): void {
    this.ticketControllerService.getCustomer({ id: id }).subscribe((res) => {
      this.customer = res;
    });
  }


  getTicketDetails(id: number){
    this.ticketControllerService.ticketDetails({id: id}).subscribe((res)=>{
      this.details = res;
      this.editForm.patchValue({
        status: this.details?.lifecycleStatus,
        category: this.details?.ticketCategory?.code,
        type: this.details?.type,
        subject: this.details?.subject,
        service: this.details?.service?.code,
        subService: this.details?.subService?.code,
        subCategory: [''],
      })

      this.assignForm.patchValue({
        assign: this.details?.assignedTo?.id
      })

    })
  }

  get status(): string {
    if (this.data && this.data.length > 0) {
      console.log('status ', this.data[0].lifecycleStatus);
      return ('TICKET ' + this.ticket.lifecycleStatus || '').toUpperCase();
    }
    return '';
  }

  initializeEditForm() {
    this.editForm = this.fb.group({
      status: [''],
      category: [''],
      type: [''],
      subject: [''],
      service: [''],
      subService: [''],
      subCategory: [''],
    });
  }

  ticketClassificationProperties() {
    this.ticketControllerService.getClassificationDetails().subscribe((res) => {
      this.ticketCategories = res.ticketCategories;
      this.service = res.services;
      this.subServices = res.subServices;
    });
  }

  getRepresentatives() {
    this.ticketControllerService.getRepresentatives().subscribe((res) => {
      this.representatives = res;
    });
  }

  update() {
    if (!this.editForm.valid) {
      return;
    }

    const params: TicketClassificationDto = {
      ticketId: this.ticketId,
      subject: this.editForm.get('subject').value,
      typeConstant: this.editForm.get('type').value,
      serviceCode: this.editForm.get('service').value,
      ticketCategoryCode: this.editForm.get('category').value,
      subServiceCode: this.editForm.get('subService').value,
      status: this.editForm.get('status').value,
    };

    this.ticketControllerService
      .classifyTicket({ ticketClassificationDto: params })
      .subscribe(
        (res) => {
          this.isUpdate = true;
          this.showSuccessMessage('Ticket updated successfully', this.isUpdate);
          this.closeTicketEdit.nativeElement.click();
        },
        (error) =>{
          this.isUpdate = true;
          this.showErrorMessage(

            this.helperService.getError(error.error.message), this.isUpdate
          );
        }
      );
  }



  assign(){
    if (!this.assignForm.valid) {
      return;
    }

    const params: TicketAssignmentDto = {
      assignedTo: this.assignForm.get('assign').value,
      ticketId: this.ticketId
    }
    this.ticketControllerService
      .ticketAssignment({ ticketAssignmentDto: params })
      .subscribe(
        (res) => {
          this.edit = false;
          this.isAssignment = true;
          this.getTicket(this.ticketId);
          this.showSuccessMessage('Ticket assigned  successfully', this.isAssignment);
        },
        (error) => {
          this.isAssignment = true;
          this.showErrorMessage(
            this.helperService.getError(error.error.message),  this.isAssignment
          );
        }
      );


  }


  editDetails(){
    this.edit = true;
  }

  goToTicketDetail(ticketId: number): void {
    this.router.navigate([`view-issue/${ticketId}`]);
  }



}
