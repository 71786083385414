/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RackDto } from './rack-dto';
import { OperatingSystemDto } from './operating-system-dto';
import { DataCenterDto } from './data-center-dto';
import { SoftwareServerDto } from './software-server-dto';
import { ConfigurationFileMetaDataDto } from './configuration-file-meta-data-dto';
import { HardwareDeploymentEnvironmentDto } from './hardware-deployment-environment-dto';
import { OperatingSystemVersionDto } from './operating-system-version-dto';
import { HardwareDeviceDto } from './hardware-device-dto';


export interface HardwareProductionUnitDto { 
    purpose: string;
    virtualMachineName: string;
    virtualMachineAddressIp: string;
    sourceUrl?: string;
    platformId: number;
    operatingSystem: OperatingSystemDto;
    operatingSystemVersionDto: OperatingSystemVersionDto;
    hardwareDevice: HardwareDeviceDto;
    hardwareDeploymentEnvironment: HardwareDeploymentEnvironmentDto;
    configurationFileMetaDataList?: Array<ConfigurationFileMetaDataDto>;
    dataCenter: DataCenterDto;
    softwareServerDto: SoftwareServerDto;
    rack: RackDto;
}

