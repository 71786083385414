<div [ngClass]="{'menu-toggled': menuToggled}" class="app">

  <header *ngIf="showPageHeader">
    <app-page-header></app-page-header>
    <app-side-nav></app-side-nav>
  </header>

  <div  [ngStyle]="{background: showPageHeader? '#fff' : '#fff'}" class="view">
    <router-outlet></router-outlet>

      <app-execution-menu *ngIf="showExecution"></app-execution-menu>
  </div>

  <footer *ngIf="showPageFooter">
  </footer>

</div>
