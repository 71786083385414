import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HelperService} from 'src/app/services/helper.service';
import {Img} from 'src/app/shared/components/img/img.type';
import {NavigationService} from "../../services/navigation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {User} from "../../models/user/user.model";
import {AuthenticationService} from "../../services/authentication.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy{
  private unsubscribeAll: Subject<any> = new Subject<any>();
  toggled = false;
  profilePhotoConfig = {} as Img;
  name = '';

  user: User | undefined;


  constructor(
    private navigationService: NavigationService,
    private helperService: HelperService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.navigationService.toggleStatus.asObservable().subscribe((status) => {
      this.toggled = status;
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: any): void {
    if(window.innerWidth <= 867){
      // this.toggled = false;
      this.navigationService.changeToggleStatus(false);
    } else{
      // this.toggled = true;
      this.navigationService.changeToggleStatus(true)
    }
  }

  get isProduction(): boolean {
    return environment.production
  }



  ngOnInit(): void {
    this.authenticationService.getUser().subscribe((value) => {
      this.user = value;
    });
  }

  closeToggle() {
    if (window.innerWidth <= 768) {
      this.navigationService.changeToggleStatus(false);
    }
  }

  get isDashboardRoute(): boolean {
    return this.router.url == 'dashboard'
  }

  close() {
    this.navigationService.changeToggleStatus(false)
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }


  isAdmin(): boolean {
    return this.user?.hasRole('ADMIN');
  }
}
