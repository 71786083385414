/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuditTrailDto { 
    ipAddress?: string;
    entityName?: string;
    recordId?: number;
    transactionId?: string;
    recordType?: AuditTrailDto.RecordTypeEnum;
    eventType?: AuditTrailDto.EventTypeEnum;
    actorEventTime?: string;
    actorEmail?: string;
    actorUUID?: string;
    recipientEmail?: string;
    recipientUUID?: string;
    description?: string;
    actorRole?: Set<string>;
    actorPermission?: Set<string>;
    eventStatus?: AuditTrailDto.EventStatusEnum;
    resource?: string;
    resourceUri?: string;
    userAgent?: string;
    externalReference?: string;
    otherDetails?: string;
}
export namespace AuditTrailDto {
    export type RecordTypeEnum = 'ACCOUNT_CREATION' | 'PORTAL_USER_CREATION' | 'PORTAL_USER_DELETION' | 'PORTAL_USER_DEACTIVATION' | 'ACCEPT_TERMS_OF_USE' | 'PORTAL_USER_ABSENCE' | 'PORTAL_USER_ACTIVATION' | 'PORTAL_USER_ROLE_UPDATE' | 'USER_LOGIN' | 'USER_LOGOUT' | 'USER_LOGIN_ERROR' | 'USER_PASSWORD_RESET' | 'SERVER_LOGIN' | 'SERVER_LOGOUT' | 'OTP_VALIDATION_SUCCESS' | 'OTP_VALIDATION_FAILURE' | 'OTP_GENERATION' | 'ACTIVITY_LOG' | 'SEARCH_USER' | 'VIEW_USER' | 'USER_PROFILE_UPDATE' | 'CREATE_CIREQUEST' | 'UPDATE_CIREQUEST' | 'UPDATE_CIREQUEST_CHECKLIST' | 'ACTIVATE_CIREQUEST' | 'UPDATE_REQUIREMENT_SPECIFICATION' | 'SEARCH_CIREQUEST' | 'PLATFORM_MANAGEMENT' | 'CUSTOMER_VALUE_MANAGEMENT' | 'SOFTWARE_MODULE_MANAGEMENT' | 'PRODUCTION_UNIT_MANAGEMENT' | 'SOFTWARE_PRODUCTION_UNIT_MAP_TO_BUSINESS_REQUIREMENT' | 'BUSINESS_REQUIREMENT_MANAGEMENT' | 'WIG_MANAGEMENT' | 'WIG_STATISTICS' | 'WIG_MAP_TO_CUSTOMER_VALUE' | 'BUSINESS_REQUIREMENT_MAP_TO_CUSTOMER_VALUE' | 'BUSINESS_REQUIREMENT_MAP_TO_MODULE' | 'BUSINESS_REQUIREMENT_MAP_CUSTOMER_VALUE' | 'CUSTOMER_VALUE_SUCCESS_CRITERIA' | 'DELEGATION_MANAGEMENT' | 'PRODUCTION_PROCESS_ENTRY_LOG_MANAGEMENT' | 'SOFTWARE_MODULE_TEST_CASE' | 'LEAD_MEASURE_COMMITMENT' | 'SCOREBOARD_MANAGEMENT' | 'PROCESS_BREACH_MANAGEMENT' | 'CADENCE_MANAGEMENT' | 'USER_ACCEPTANCE_TEST' | 'KEY_RESULT_MANAGEMENT' | 'USER_ACCEPTANCE_TEST_MAP_TO_PRODUCTION_UNIT' | 'USER_ACCEPTANCE_TEST_NOTIFICATION' | 'DOWNLOAD_PA' | 'FINANCIAL_COST_MANAGEMENT' | 'HARDWARE_PRODUCTION_UNIT_MAP_TO_PRODUCTION_UNIT' | 'USER_STORY_MANAGEMENT' | 'EPIC' | 'ACCEPTANCE_CRITERIA' | 'DOWNLOAD_BRD' | 'DOWNLOAD_UI_DESIGN_APPROVAL' | 'DOWNLOAD_UML_DESIGN_APPROVAL' | 'DOWNLOAD_MRP_APPROVAL_FORM' | 'DOWNLOAD_CUSTOMER_SATISFACTION_FORM' | 'DOWNLOAD_PLAYER_STATISTICS_REPORT';
    export const RecordTypeEnum = {
        AccountCreation: 'ACCOUNT_CREATION' as RecordTypeEnum,
        PortalUserCreation: 'PORTAL_USER_CREATION' as RecordTypeEnum,
        PortalUserDeletion: 'PORTAL_USER_DELETION' as RecordTypeEnum,
        PortalUserDeactivation: 'PORTAL_USER_DEACTIVATION' as RecordTypeEnum,
        AcceptTermsOfUse: 'ACCEPT_TERMS_OF_USE' as RecordTypeEnum,
        PortalUserAbsence: 'PORTAL_USER_ABSENCE' as RecordTypeEnum,
        PortalUserActivation: 'PORTAL_USER_ACTIVATION' as RecordTypeEnum,
        PortalUserRoleUpdate: 'PORTAL_USER_ROLE_UPDATE' as RecordTypeEnum,
        UserLogin: 'USER_LOGIN' as RecordTypeEnum,
        UserLogout: 'USER_LOGOUT' as RecordTypeEnum,
        UserLoginError: 'USER_LOGIN_ERROR' as RecordTypeEnum,
        UserPasswordReset: 'USER_PASSWORD_RESET' as RecordTypeEnum,
        ServerLogin: 'SERVER_LOGIN' as RecordTypeEnum,
        ServerLogout: 'SERVER_LOGOUT' as RecordTypeEnum,
        OtpValidationSuccess: 'OTP_VALIDATION_SUCCESS' as RecordTypeEnum,
        OtpValidationFailure: 'OTP_VALIDATION_FAILURE' as RecordTypeEnum,
        OtpGeneration: 'OTP_GENERATION' as RecordTypeEnum,
        ActivityLog: 'ACTIVITY_LOG' as RecordTypeEnum,
        SearchUser: 'SEARCH_USER' as RecordTypeEnum,
        ViewUser: 'VIEW_USER' as RecordTypeEnum,
        UserProfileUpdate: 'USER_PROFILE_UPDATE' as RecordTypeEnum,
        CreateCirequest: 'CREATE_CIREQUEST' as RecordTypeEnum,
        UpdateCirequest: 'UPDATE_CIREQUEST' as RecordTypeEnum,
        UpdateCirequestChecklist: 'UPDATE_CIREQUEST_CHECKLIST' as RecordTypeEnum,
        ActivateCirequest: 'ACTIVATE_CIREQUEST' as RecordTypeEnum,
        UpdateRequirementSpecification: 'UPDATE_REQUIREMENT_SPECIFICATION' as RecordTypeEnum,
        SearchCirequest: 'SEARCH_CIREQUEST' as RecordTypeEnum,
        PlatformManagement: 'PLATFORM_MANAGEMENT' as RecordTypeEnum,
        CustomerValueManagement: 'CUSTOMER_VALUE_MANAGEMENT' as RecordTypeEnum,
        SoftwareModuleManagement: 'SOFTWARE_MODULE_MANAGEMENT' as RecordTypeEnum,
        ProductionUnitManagement: 'PRODUCTION_UNIT_MANAGEMENT' as RecordTypeEnum,
        SoftwareProductionUnitMapToBusinessRequirement: 'SOFTWARE_PRODUCTION_UNIT_MAP_TO_BUSINESS_REQUIREMENT' as RecordTypeEnum,
        BusinessRequirementManagement: 'BUSINESS_REQUIREMENT_MANAGEMENT' as RecordTypeEnum,
        WigManagement: 'WIG_MANAGEMENT' as RecordTypeEnum,
        WigStatistics: 'WIG_STATISTICS' as RecordTypeEnum,
        WigMapToCustomerValue: 'WIG_MAP_TO_CUSTOMER_VALUE' as RecordTypeEnum,
        BusinessRequirementMapToCustomerValue: 'BUSINESS_REQUIREMENT_MAP_TO_CUSTOMER_VALUE' as RecordTypeEnum,
        BusinessRequirementMapToModule: 'BUSINESS_REQUIREMENT_MAP_TO_MODULE' as RecordTypeEnum,
        BusinessRequirementMapCustomerValue: 'BUSINESS_REQUIREMENT_MAP_CUSTOMER_VALUE' as RecordTypeEnum,
        CustomerValueSuccessCriteria: 'CUSTOMER_VALUE_SUCCESS_CRITERIA' as RecordTypeEnum,
        DelegationManagement: 'DELEGATION_MANAGEMENT' as RecordTypeEnum,
        ProductionProcessEntryLogManagement: 'PRODUCTION_PROCESS_ENTRY_LOG_MANAGEMENT' as RecordTypeEnum,
        SoftwareModuleTestCase: 'SOFTWARE_MODULE_TEST_CASE' as RecordTypeEnum,
        LeadMeasureCommitment: 'LEAD_MEASURE_COMMITMENT' as RecordTypeEnum,
        ScoreboardManagement: 'SCOREBOARD_MANAGEMENT' as RecordTypeEnum,
        ProcessBreachManagement: 'PROCESS_BREACH_MANAGEMENT' as RecordTypeEnum,
        CadenceManagement: 'CADENCE_MANAGEMENT' as RecordTypeEnum,
        UserAcceptanceTest: 'USER_ACCEPTANCE_TEST' as RecordTypeEnum,
        KeyResultManagement: 'KEY_RESULT_MANAGEMENT' as RecordTypeEnum,
        UserAcceptanceTestMapToProductionUnit: 'USER_ACCEPTANCE_TEST_MAP_TO_PRODUCTION_UNIT' as RecordTypeEnum,
        UserAcceptanceTestNotification: 'USER_ACCEPTANCE_TEST_NOTIFICATION' as RecordTypeEnum,
        DownloadPa: 'DOWNLOAD_PA' as RecordTypeEnum,
        FinancialCostManagement: 'FINANCIAL_COST_MANAGEMENT' as RecordTypeEnum,
        HardwareProductionUnitMapToProductionUnit: 'HARDWARE_PRODUCTION_UNIT_MAP_TO_PRODUCTION_UNIT' as RecordTypeEnum,
        UserStoryManagement: 'USER_STORY_MANAGEMENT' as RecordTypeEnum,
        Epic: 'EPIC' as RecordTypeEnum,
        AcceptanceCriteria: 'ACCEPTANCE_CRITERIA' as RecordTypeEnum,
        DownloadBrd: 'DOWNLOAD_BRD' as RecordTypeEnum,
        DownloadUiDesignApproval: 'DOWNLOAD_UI_DESIGN_APPROVAL' as RecordTypeEnum,
        DownloadUmlDesignApproval: 'DOWNLOAD_UML_DESIGN_APPROVAL' as RecordTypeEnum,
        DownloadMrpApprovalForm: 'DOWNLOAD_MRP_APPROVAL_FORM' as RecordTypeEnum,
        DownloadCustomerSatisfactionForm: 'DOWNLOAD_CUSTOMER_SATISFACTION_FORM' as RecordTypeEnum,
        DownloadPlayerStatisticsReport: 'DOWNLOAD_PLAYER_STATISTICS_REPORT' as RecordTypeEnum
    };
    export type EventTypeEnum = 'CREATE' | 'DELETE' | 'UPDATE' | 'SELECT' | 'LOGIN' | 'LOGOUT' | 'OPERATION_LOG' | 'GENERIC_LOG' | 'SIEM';
    export const EventTypeEnum = {
        Create: 'CREATE' as EventTypeEnum,
        Delete: 'DELETE' as EventTypeEnum,
        Update: 'UPDATE' as EventTypeEnum,
        Select: 'SELECT' as EventTypeEnum,
        Login: 'LOGIN' as EventTypeEnum,
        Logout: 'LOGOUT' as EventTypeEnum,
        OperationLog: 'OPERATION_LOG' as EventTypeEnum,
        GenericLog: 'GENERIC_LOG' as EventTypeEnum,
        Siem: 'SIEM' as EventTypeEnum
    };
    export type EventStatusEnum = 'SUCCESSFUL' | 'FAILED';
    export const EventStatusEnum = {
        Successful: 'SUCCESSFUL' as EventStatusEnum,
        Failed: 'FAILED' as EventStatusEnum
    };
}


