/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BusinessRequirementPojo } from '../model/models';
import { DataCenterPojo } from '../model/models';
import { HPUToProductionUnitMapperDto } from '../model/models';
import { HardwareProductionUnitDto } from '../model/models';
import { NameCodePojo } from '../model/models';
import { NameIdPojo } from '../model/models';
import { OperatingSystemPojo } from '../model/models';
import { OperatingSystemVersionPojo } from '../model/models';
import { QueryResultsHardwareProductionUnitPojo } from '../model/models';
import { QueryResultsSoftwareProductionUnitPojo } from '../model/models';
import { SoftwareProductionUnitDto } from '../model/models';
import { SoftwareProductionUnitPojo } from '../model/models';
import { UploadResponseHardwareProductionUnitUploadDtoHardwareProductionUnitUploadDto } from '../model/models';
import { UploadResponseProductionUnitUploadDtoProductionUnitUploadDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateHardwareProductionUnitRequestParams {
    hardwareProductionUnitDto: HardwareProductionUnitDto;
}

export interface CreateSoftwareProductionUnitRequestParams {
    softwareProductionUnitDto: Array<SoftwareProductionUnitDto>;
}

export interface DeleteHardwareProductionUnitRequestParams {
    hardwareProductionUnitId: number;
}

export interface DeleteMappingRequestParams {
    id: number;
    requestBody: Array<number>;
}

export interface DeleteSoftwareProductionUnitRequestParams {
    softwareProductionUnitId: number;
}

export interface DownloadHardProductionUnitUploadErrorSheetRequestParams {
    fileName: string;
}

export interface DownloadProductionUnitUploadErrorSheetRequestParams {
    fileName: string;
}

export interface FetchOperatingSystemRequestParams {
    category: 'HYPERVISOR' | 'HOST' | 'GUEST';
}

export interface FetchOperatingSystemVersionRequestParams {
    operatingSystemId: number;
}

export interface GetMappedRequirementsRequestParams {
    id: number;
}

export interface MapHPUToProductionUnitRequestParams {
    platformId: number;
    hPUToProductionUnitMapperDto: Array<HPUToProductionUnitMapperDto>;
}

export interface MapProductionUnitToBusinessRequirementRequestParams {
    id: number;
    requestBody: Array<number>;
}

export interface ProcessHardwareProductionUnitUploadViaExcelRequestParams {
    publish: boolean;
    platformId: number;
    file: Blob;
}

export interface ProcessProductionUnitUploadViaExcelRequestParams {
    publish: boolean;
    file: Blob;
}

export interface ResetProductionUnitRequestParams {
    id: number;
    reasons: Array<'UPGRADE' | 'REQUIREMENT_UPDATE' | 'BETA_MONITORING_FEEDBACK' | 'BUG_IN_PRODUCTION'>;
    optionalResponsibleId?: number;
}

export interface SearchHardwareProductionUnitRequestParams {
    identifier?: string;
    virtualMachineName?: string;
    deploymentEnvironment?: string;
    purpose?: string;
    operatingSystem?: string;
    platform?: string;
    sourceUrl?: string;
    createdAt?: string;
    operatingSystemVersion?: string;
    hardwareDevice?: string;
    createdBy?: string;
    id?: number;
    virtualMachineIpAddress?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    definitionStatus?: 'DEFINED' | 'PARTIALLY_DEFINED' | 'NOT_DEFINED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    platformId?: number;
    createdAfter?: string;
    priorityItems?: Array<object>;
    hardwareDeploymentEnvironment?: string;
    isDropDown?: boolean;
    limit?: number;
    ids?: Array<object>;
    createdBefore?: string;
    keyword?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface SearchSoftwareProductionUnitRequestParams {
    currentLifecycleStatus?: 'ASSIGNMENT' | 'RELEASE_PLANNING' | 'RELEASE_PLANNING_FAIL' | 'DESIGN' | 'DESIGN_FAIL' | 'IMPLEMENTATION' | 'CODE_REVIEW' | 'CODE_REVIEW_FAIL' | 'FUNCTIONAL_TESTING' | 'FUNCTIONAL_TESTING_FAIL' | 'RELEASE' | 'RELEASE_FAIL' | 'USER_ACCEPTANCE_TESTING' | 'USER_ACCEPTANCE_TESTING_FAIL' | 'BETA_SUCCESS' | 'RESET';
    code?: string;
    softwareModule?: string;
    currentAcceptanceStatus?: 'OK' | 'NOT_OK' | 'NOT_CHECKED';
    moduleName?: string;
    type?: string;
    unitType?: string;
    currentCICompletionTime?: string;
    currentPlannedCICompletionDate?: string;
    createdAt?: string;
    currentCompletionStatus?: 'COMPLETED' | 'NOT_COMPLETED';
    currentCompletionTime?: string;
    createdBy?: string;
    name?: string;
    currentAcceptanceTime?: string;
    signedOffView?: string;
    currentPlannedAcceptanceTime?: string;
    currentCICompletionStatus?: 'COMPLETED' | 'NOT_COMPLETED';
    id?: number;
    lifecycleStatusUpdatedAt?: string;
    moduleId?: number;
    currentAssignedPlayer?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    definitionStatus?: 'DEFINED' | 'PARTIALLY_DEFINED' | 'NOT_DEFINED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    userAcceptanceTestId?: number;
    releasePlannedBefore?: string;
    releasePlannedAfter?: string;
    priorityItems?: Array<object>;
    shouldHaveCommitment?: boolean;
    useCaseIds?: Array<object>;
    isDropDown?: boolean;
    limit?: number;
    keyword?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
    customerValueId?: number;
    offset?: number;
    uatPlannedBefore?: string;
    uatPlannedAfter?: string;
    platformId?: number;
    createdAfter?: string;
    releasePlanned?: boolean;
    unitTypeCode?: string;
    uatPlanned?: boolean;
    useCaseId?: number;
    ids?: Array<object>;
    createdBefore?: string;
}

export interface UpdateHardwareProductionUnitRequestParams {
    id: number;
    hardwareProductionUnitDto: HardwareProductionUnitDto;
}

export interface UpdateProductionUnitRequestParams {
    id: number;
    softwareProductionUnitDto: SoftwareProductionUnitDto;
}


@Injectable({
  providedIn: 'root'
})
export class ProductionUnitControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createHardwareProductionUnit(requestParameters: CreateHardwareProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public createHardwareProductionUnit(requestParameters: CreateHardwareProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public createHardwareProductionUnit(requestParameters: CreateHardwareProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public createHardwareProductionUnit(requestParameters: CreateHardwareProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const hardwareProductionUnitDto = requestParameters.hardwareProductionUnitDto;
        if (hardwareProductionUnitDto === null || hardwareProductionUnitDto === undefined) {
            throw new Error('Required parameter hardwareProductionUnitDto was null or undefined when calling createHardwareProductionUnit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/software-production-unit/hardware-pu/create`,
            hardwareProductionUnitDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSoftwareProductionUnit(requestParameters: CreateSoftwareProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<NameIdPojo>>;
    public createSoftwareProductionUnit(requestParameters: CreateSoftwareProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<NameIdPojo>>>;
    public createSoftwareProductionUnit(requestParameters: CreateSoftwareProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<NameIdPojo>>>;
    public createSoftwareProductionUnit(requestParameters: CreateSoftwareProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const softwareProductionUnitDto = requestParameters.softwareProductionUnitDto;
        if (softwareProductionUnitDto === null || softwareProductionUnitDto === undefined) {
            throw new Error('Required parameter softwareProductionUnitDto was null or undefined when calling createSoftwareProductionUnit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<NameIdPojo>>(`${this.configuration.basePath}/software-production-unit`,
            softwareProductionUnitDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteHardwareProductionUnit(requestParameters: DeleteHardwareProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public deleteHardwareProductionUnit(requestParameters: DeleteHardwareProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public deleteHardwareProductionUnit(requestParameters: DeleteHardwareProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public deleteHardwareProductionUnit(requestParameters: DeleteHardwareProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const hardwareProductionUnitId = requestParameters.hardwareProductionUnitId;
        if (hardwareProductionUnitId === null || hardwareProductionUnitId === undefined) {
            throw new Error('Required parameter hardwareProductionUnitId was null or undefined when calling deleteHardwareProductionUnit.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (hardwareProductionUnitId !== undefined && hardwareProductionUnitId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hardwareProductionUnitId, 'HardwareProductionUnitId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/software-production-unit/delete-hardware-pu`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMapping(requestParameters: DeleteMappingRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<SoftwareProductionUnitPojo>;
    public deleteMapping(requestParameters: DeleteMappingRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<SoftwareProductionUnitPojo>>;
    public deleteMapping(requestParameters: DeleteMappingRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<SoftwareProductionUnitPojo>>;
    public deleteMapping(requestParameters: DeleteMappingRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteMapping.');
        }
        const requestBody = requestParameters.requestBody;
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling deleteMapping.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SoftwareProductionUnitPojo>(`${this.configuration.basePath}/software-production-unit/${encodeURIComponent(String(id))}/delete-business-requirement-mapping`,
            requestBody,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSoftwareProductionUnit(requestParameters: DeleteSoftwareProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public deleteSoftwareProductionUnit(requestParameters: DeleteSoftwareProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public deleteSoftwareProductionUnit(requestParameters: DeleteSoftwareProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public deleteSoftwareProductionUnit(requestParameters: DeleteSoftwareProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const softwareProductionUnitId = requestParameters.softwareProductionUnitId;
        if (softwareProductionUnitId === null || softwareProductionUnitId === undefined) {
            throw new Error('Required parameter softwareProductionUnitId was null or undefined when calling deleteSoftwareProductionUnit.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (softwareProductionUnitId !== undefined && softwareProductionUnitId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>softwareProductionUnitId, 'softwareProductionUnitId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/software-production-unit/delete`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadHardProductionUnitUploadErrorSheet(requestParameters: DownloadHardProductionUnitUploadErrorSheetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public downloadHardProductionUnitUploadErrorSheet(requestParameters: DownloadHardProductionUnitUploadErrorSheetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public downloadHardProductionUnitUploadErrorSheet(requestParameters: DownloadHardProductionUnitUploadErrorSheetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public downloadHardProductionUnitUploadErrorSheet(requestParameters: DownloadHardProductionUnitUploadErrorSheetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const fileName = requestParameters.fileName;
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling downloadHardProductionUnitUploadErrorSheet.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fileName !== undefined && fileName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fileName, 'fileName');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/software-production-unit/hardware-pu-upload-error/download`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadHardwareProductionUnitExcelTemplate(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public downloadHardwareProductionUnitExcelTemplate(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public downloadHardwareProductionUnitExcelTemplate(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public downloadHardwareProductionUnitExcelTemplate(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/software-production-unit/hardware-pu-upload-template`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadProductionUnitExcelTemplate(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public downloadProductionUnitExcelTemplate(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public downloadProductionUnitExcelTemplate(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public downloadProductionUnitExcelTemplate(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/software-production-unit/pu-upload-template`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadProductionUnitUploadErrorSheet(requestParameters: DownloadProductionUnitUploadErrorSheetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public downloadProductionUnitUploadErrorSheet(requestParameters: DownloadProductionUnitUploadErrorSheetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public downloadProductionUnitUploadErrorSheet(requestParameters: DownloadProductionUnitUploadErrorSheetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public downloadProductionUnitUploadErrorSheet(requestParameters: DownloadProductionUnitUploadErrorSheetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const fileName = requestParameters.fileName;
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling downloadProductionUnitUploadErrorSheet.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fileName !== undefined && fileName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fileName, 'fileName');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/software-production-unit/pu-upload-error/download`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchDataCenter(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<DataCenterPojo>>;
    public fetchDataCenter(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<DataCenterPojo>>>;
    public fetchDataCenter(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<DataCenterPojo>>>;
    public fetchDataCenter(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<DataCenterPojo>>(`${this.configuration.basePath}/software-production-unit/fetch-data-center`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchOperatingSystem(requestParameters: FetchOperatingSystemRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<OperatingSystemPojo>>;
    public fetchOperatingSystem(requestParameters: FetchOperatingSystemRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<OperatingSystemPojo>>>;
    public fetchOperatingSystem(requestParameters: FetchOperatingSystemRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<OperatingSystemPojo>>>;
    public fetchOperatingSystem(requestParameters: FetchOperatingSystemRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const category = requestParameters.category;
        if (category === null || category === undefined) {
            throw new Error('Required parameter category was null or undefined when calling fetchOperatingSystem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<OperatingSystemPojo>>(`${this.configuration.basePath}/software-production-unit/${encodeURIComponent(String(category))}/fetch-operating-system`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchOperatingSystemVersion(requestParameters: FetchOperatingSystemVersionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<OperatingSystemVersionPojo>>;
    public fetchOperatingSystemVersion(requestParameters: FetchOperatingSystemVersionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<OperatingSystemVersionPojo>>>;
    public fetchOperatingSystemVersion(requestParameters: FetchOperatingSystemVersionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<OperatingSystemVersionPojo>>>;
    public fetchOperatingSystemVersion(requestParameters: FetchOperatingSystemVersionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const operatingSystemId = requestParameters.operatingSystemId;
        if (operatingSystemId === null || operatingSystemId === undefined) {
            throw new Error('Required parameter operatingSystemId was null or undefined when calling fetchOperatingSystemVersion.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<OperatingSystemVersionPojo>>(`${this.configuration.basePath}/software-production-unit/${encodeURIComponent(String(operatingSystemId))}/fetch-operating-system-version`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchProductionUnitType(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<NameCodePojo>>;
    public fetchProductionUnitType(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<NameCodePojo>>>;
    public fetchProductionUnitType(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<NameCodePojo>>>;
    public fetchProductionUnitType(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<NameCodePojo>>(`${this.configuration.basePath}/software-production-unit/unit-types`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSoftwareProductionUnit(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<SoftwareProductionUnitPojo>>;
    public getAllSoftwareProductionUnit(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<SoftwareProductionUnitPojo>>>;
    public getAllSoftwareProductionUnit(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<SoftwareProductionUnitPojo>>>;
    public getAllSoftwareProductionUnit(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SoftwareProductionUnitPojo>>(`${this.configuration.basePath}/software-production-unit/get-all-unit`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMappedRequirements(requestParameters: GetMappedRequirementsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<BusinessRequirementPojo>>;
    public getMappedRequirements(requestParameters: GetMappedRequirementsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<BusinessRequirementPojo>>>;
    public getMappedRequirements(requestParameters: GetMappedRequirementsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<BusinessRequirementPojo>>>;
    public getMappedRequirements(requestParameters: GetMappedRequirementsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getMappedRequirements.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<BusinessRequirementPojo>>(`${this.configuration.basePath}/software-production-unit/${encodeURIComponent(String(id))}/get-mapped-requirements`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapHPUToProductionUnit(requestParameters: MapHPUToProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public mapHPUToProductionUnit(requestParameters: MapHPUToProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public mapHPUToProductionUnit(requestParameters: MapHPUToProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public mapHPUToProductionUnit(requestParameters: MapHPUToProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const platformId = requestParameters.platformId;
        if (platformId === null || platformId === undefined) {
            throw new Error('Required parameter platformId was null or undefined when calling mapHPUToProductionUnit.');
        }
        const hPUToProductionUnitMapperDto = requestParameters.hPUToProductionUnitMapperDto;
        if (hPUToProductionUnitMapperDto === null || hPUToProductionUnitMapperDto === undefined) {
            throw new Error('Required parameter hPUToProductionUnitMapperDto was null or undefined when calling mapHPUToProductionUnit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/software-production-unit/${encodeURIComponent(String(platformId))}/map-hpu-pu`,
            hPUToProductionUnitMapperDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapProductionUnitToBusinessRequirement(requestParameters: MapProductionUnitToBusinessRequirementRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<SoftwareProductionUnitPojo>;
    public mapProductionUnitToBusinessRequirement(requestParameters: MapProductionUnitToBusinessRequirementRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<SoftwareProductionUnitPojo>>;
    public mapProductionUnitToBusinessRequirement(requestParameters: MapProductionUnitToBusinessRequirementRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<SoftwareProductionUnitPojo>>;
    public mapProductionUnitToBusinessRequirement(requestParameters: MapProductionUnitToBusinessRequirementRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mapProductionUnitToBusinessRequirement.');
        }
        const requestBody = requestParameters.requestBody;
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling mapProductionUnitToBusinessRequirement.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SoftwareProductionUnitPojo>(`${this.configuration.basePath}/software-production-unit/${encodeURIComponent(String(id))}/map-to-business-requirement`,
            requestBody,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processHardwareProductionUnitUploadViaExcel(requestParameters: ProcessHardwareProductionUnitUploadViaExcelRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<UploadResponseHardwareProductionUnitUploadDtoHardwareProductionUnitUploadDto>;
    public processHardwareProductionUnitUploadViaExcel(requestParameters: ProcessHardwareProductionUnitUploadViaExcelRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<UploadResponseHardwareProductionUnitUploadDtoHardwareProductionUnitUploadDto>>;
    public processHardwareProductionUnitUploadViaExcel(requestParameters: ProcessHardwareProductionUnitUploadViaExcelRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<UploadResponseHardwareProductionUnitUploadDtoHardwareProductionUnitUploadDto>>;
    public processHardwareProductionUnitUploadViaExcel(requestParameters: ProcessHardwareProductionUnitUploadViaExcelRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const publish = requestParameters.publish;
        if (publish === null || publish === undefined) {
            throw new Error('Required parameter publish was null or undefined when calling processHardwareProductionUnitUploadViaExcel.');
        }
        const platformId = requestParameters.platformId;
        if (platformId === null || platformId === undefined) {
            throw new Error('Required parameter platformId was null or undefined when calling processHardwareProductionUnitUploadViaExcel.');
        }
        const file = requestParameters.file;
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling processHardwareProductionUnitUploadViaExcel.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (publish !== undefined && publish !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>publish, 'publish');
        }
        if (platformId !== undefined && platformId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platformId, 'platformId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<UploadResponseHardwareProductionUnitUploadDtoHardwareProductionUnitUploadDto>(`${this.configuration.basePath}/software-production-unit/hardware-pu-upload`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processProductionUnitUploadViaExcel(requestParameters: ProcessProductionUnitUploadViaExcelRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<UploadResponseProductionUnitUploadDtoProductionUnitUploadDto>;
    public processProductionUnitUploadViaExcel(requestParameters: ProcessProductionUnitUploadViaExcelRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<UploadResponseProductionUnitUploadDtoProductionUnitUploadDto>>;
    public processProductionUnitUploadViaExcel(requestParameters: ProcessProductionUnitUploadViaExcelRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<UploadResponseProductionUnitUploadDtoProductionUnitUploadDto>>;
    public processProductionUnitUploadViaExcel(requestParameters: ProcessProductionUnitUploadViaExcelRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const publish = requestParameters.publish;
        if (publish === null || publish === undefined) {
            throw new Error('Required parameter publish was null or undefined when calling processProductionUnitUploadViaExcel.');
        }
        const file = requestParameters.file;
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling processProductionUnitUploadViaExcel.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (publish !== undefined && publish !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>publish, 'publish');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<UploadResponseProductionUnitUploadDtoProductionUnitUploadDto>(`${this.configuration.basePath}/software-production-unit/pu-upload`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetProductionUnit(requestParameters: ResetProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public resetProductionUnit(requestParameters: ResetProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public resetProductionUnit(requestParameters: ResetProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public resetProductionUnit(requestParameters: ResetProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling resetProductionUnit.');
        }
        const reasons = requestParameters.reasons;
        if (reasons === null || reasons === undefined) {
            throw new Error('Required parameter reasons was null or undefined when calling resetProductionUnit.');
        }
        const optionalResponsibleId = requestParameters.optionalResponsibleId;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (reasons) {
            reasons.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'reasons');
            })
        }
        if (optionalResponsibleId !== undefined && optionalResponsibleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>optionalResponsibleId, 'optionalResponsibleId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/software-production-unit/${encodeURIComponent(String(id))}/reset`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Hardware Production Unit
     * Filter Hardware Production Unit
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchHardwareProductionUnit(requestParameters: SearchHardwareProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsHardwareProductionUnitPojo>;
    public searchHardwareProductionUnit(requestParameters: SearchHardwareProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsHardwareProductionUnitPojo>>;
    public searchHardwareProductionUnit(requestParameters: SearchHardwareProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsHardwareProductionUnitPojo>>;
    public searchHardwareProductionUnit(requestParameters: SearchHardwareProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const identifier = requestParameters.identifier;
        const virtualMachineName = requestParameters.virtualMachineName;
        const deploymentEnvironment = requestParameters.deploymentEnvironment;
        const purpose = requestParameters.purpose;
        const operatingSystem = requestParameters.operatingSystem;
        const platform = requestParameters.platform;
        const sourceUrl = requestParameters.sourceUrl;
        const createdAt = requestParameters.createdAt;
        const operatingSystemVersion = requestParameters.operatingSystemVersion;
        const hardwareDevice = requestParameters.hardwareDevice;
        const createdBy = requestParameters.createdBy;
        const id = requestParameters.id;
        const virtualMachineIpAddress = requestParameters.virtualMachineIpAddress;
        const status = requestParameters.status;
        const definitionStatus = requestParameters.definitionStatus;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const platformId = requestParameters.platformId;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const hardwareDeploymentEnvironment = requestParameters.hardwareDeploymentEnvironment;
        const isDropDown = requestParameters.isDropDown;
        const limit = requestParameters.limit;
        const ids = requestParameters.ids;
        const createdBefore = requestParameters.createdBefore;
        const keyword = requestParameters.keyword;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (virtualMachineName !== undefined && virtualMachineName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>virtualMachineName, 'virtualMachineName');
        }
        if (deploymentEnvironment !== undefined && deploymentEnvironment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deploymentEnvironment, 'deploymentEnvironment');
        }
        if (purpose !== undefined && purpose !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>purpose, 'purpose');
        }
        if (operatingSystem !== undefined && operatingSystem !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>operatingSystem, 'operatingSystem');
        }
        if (platform !== undefined && platform !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platform, 'platform');
        }
        if (sourceUrl !== undefined && sourceUrl !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sourceUrl, 'sourceUrl');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (operatingSystemVersion !== undefined && operatingSystemVersion !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>operatingSystemVersion, 'operatingSystemVersion');
        }
        if (hardwareDevice !== undefined && hardwareDevice !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hardwareDevice, 'hardwareDevice');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (virtualMachineIpAddress !== undefined && virtualMachineIpAddress !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>virtualMachineIpAddress, 'virtualMachineIpAddress');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (definitionStatus !== undefined && definitionStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>definitionStatus, 'definitionStatus');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (platformId !== undefined && platformId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platformId, 'platformId');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (hardwareDeploymentEnvironment !== undefined && hardwareDeploymentEnvironment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hardwareDeploymentEnvironment, 'hardwareDeploymentEnvironment');
        }
        if (isDropDown !== undefined && isDropDown !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isDropDown, 'isDropDown');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (ids) {
            ids.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'ids');
            })
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsHardwareProductionUnitPojo>(`${this.configuration.basePath}/software-production-unit/search`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Software Production Unit
     * Filter Software Production Unit
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSoftwareProductionUnit(requestParameters: SearchSoftwareProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsSoftwareProductionUnitPojo>;
    public searchSoftwareProductionUnit(requestParameters: SearchSoftwareProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsSoftwareProductionUnitPojo>>;
    public searchSoftwareProductionUnit(requestParameters: SearchSoftwareProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsSoftwareProductionUnitPojo>>;
    public searchSoftwareProductionUnit(requestParameters: SearchSoftwareProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const currentLifecycleStatus = requestParameters.currentLifecycleStatus;
        const code = requestParameters.code;
        const softwareModule = requestParameters.softwareModule;
        const currentAcceptanceStatus = requestParameters.currentAcceptanceStatus;
        const moduleName = requestParameters.moduleName;
        const type = requestParameters.type;
        const unitType = requestParameters.unitType;
        const currentCICompletionTime = requestParameters.currentCICompletionTime;
        const currentPlannedCICompletionDate = requestParameters.currentPlannedCICompletionDate;
        const createdAt = requestParameters.createdAt;
        const currentCompletionStatus = requestParameters.currentCompletionStatus;
        const currentCompletionTime = requestParameters.currentCompletionTime;
        const createdBy = requestParameters.createdBy;
        const name = requestParameters.name;
        const currentAcceptanceTime = requestParameters.currentAcceptanceTime;
        const signedOffView = requestParameters.signedOffView;
        const currentPlannedAcceptanceTime = requestParameters.currentPlannedAcceptanceTime;
        const currentCICompletionStatus = requestParameters.currentCICompletionStatus;
        const id = requestParameters.id;
        const lifecycleStatusUpdatedAt = requestParameters.lifecycleStatusUpdatedAt;
        const moduleId = requestParameters.moduleId;
        const currentAssignedPlayer = requestParameters.currentAssignedPlayer;
        const status = requestParameters.status;
        const definitionStatus = requestParameters.definitionStatus;
        const templateType = requestParameters.templateType;
        const userAcceptanceTestId = requestParameters.userAcceptanceTestId;
        const releasePlannedBefore = requestParameters.releasePlannedBefore;
        const releasePlannedAfter = requestParameters.releasePlannedAfter;
        const priorityItems = requestParameters.priorityItems;
        const shouldHaveCommitment = requestParameters.shouldHaveCommitment;
        const useCaseIds = requestParameters.useCaseIds;
        const isDropDown = requestParameters.isDropDown;
        const limit = requestParameters.limit;
        const keyword = requestParameters.keyword;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;
        const customerValueId = requestParameters.customerValueId;
        const offset = requestParameters.offset;
        const uatPlannedBefore = requestParameters.uatPlannedBefore;
        const uatPlannedAfter = requestParameters.uatPlannedAfter;
        const platformId = requestParameters.platformId;
        const createdAfter = requestParameters.createdAfter;
        const releasePlanned = requestParameters.releasePlanned;
        const unitTypeCode = requestParameters.unitTypeCode;
        const uatPlanned = requestParameters.uatPlanned;
        const useCaseId = requestParameters.useCaseId;
        const ids = requestParameters.ids;
        const createdBefore = requestParameters.createdBefore;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (currentLifecycleStatus !== undefined && currentLifecycleStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentLifecycleStatus, 'currentLifecycleStatus');
        }
        if (code !== undefined && code !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>code, 'code');
        }
        if (softwareModule !== undefined && softwareModule !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>softwareModule, 'softwareModule');
        }
        if (currentAcceptanceStatus !== undefined && currentAcceptanceStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentAcceptanceStatus, 'currentAcceptanceStatus');
        }
        if (moduleName !== undefined && moduleName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>moduleName, 'moduleName');
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }
        if (unitType !== undefined && unitType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>unitType, 'unitType');
        }
        if (currentCICompletionTime !== undefined && currentCICompletionTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentCICompletionTime, 'currentCICompletionTime');
        }
        if (currentPlannedCICompletionDate !== undefined && currentPlannedCICompletionDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentPlannedCICompletionDate, 'currentPlannedCICompletionDate');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (currentCompletionStatus !== undefined && currentCompletionStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentCompletionStatus, 'currentCompletionStatus');
        }
        if (currentCompletionTime !== undefined && currentCompletionTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentCompletionTime, 'currentCompletionTime');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (currentAcceptanceTime !== undefined && currentAcceptanceTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentAcceptanceTime, 'currentAcceptanceTime');
        }
        if (signedOffView !== undefined && signedOffView !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>signedOffView, 'signedOffView');
        }
        if (currentPlannedAcceptanceTime !== undefined && currentPlannedAcceptanceTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentPlannedAcceptanceTime, 'currentPlannedAcceptanceTime');
        }
        if (currentCICompletionStatus !== undefined && currentCICompletionStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentCICompletionStatus, 'currentCICompletionStatus');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (lifecycleStatusUpdatedAt !== undefined && lifecycleStatusUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lifecycleStatusUpdatedAt, 'lifecycleStatusUpdatedAt');
        }
        if (moduleId !== undefined && moduleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>moduleId, 'moduleId');
        }
        if (currentAssignedPlayer !== undefined && currentAssignedPlayer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currentAssignedPlayer, 'currentAssignedPlayer');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (definitionStatus !== undefined && definitionStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>definitionStatus, 'definitionStatus');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (userAcceptanceTestId !== undefined && userAcceptanceTestId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userAcceptanceTestId, 'userAcceptanceTestId');
        }
        if (releasePlannedBefore !== undefined && releasePlannedBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>releasePlannedBefore, 'releasePlannedBefore');
        }
        if (releasePlannedAfter !== undefined && releasePlannedAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>releasePlannedAfter, 'releasePlannedAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (shouldHaveCommitment !== undefined && shouldHaveCommitment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>shouldHaveCommitment, 'shouldHaveCommitment');
        }
        if (useCaseIds) {
            useCaseIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'useCaseIds');
            })
        }
        if (isDropDown !== undefined && isDropDown !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isDropDown, 'isDropDown');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }
        if (customerValueId !== undefined && customerValueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValueId, 'customerValueId');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (uatPlannedBefore !== undefined && uatPlannedBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>uatPlannedBefore, 'uatPlannedBefore');
        }
        if (uatPlannedAfter !== undefined && uatPlannedAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>uatPlannedAfter, 'uatPlannedAfter');
        }
        if (platformId !== undefined && platformId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>platformId, 'platformId');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (releasePlanned !== undefined && releasePlanned !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>releasePlanned, 'releasePlanned');
        }
        if (unitTypeCode !== undefined && unitTypeCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>unitTypeCode, 'unitTypeCode');
        }
        if (uatPlanned !== undefined && uatPlanned !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>uatPlanned, 'uatPlanned');
        }
        if (useCaseId !== undefined && useCaseId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>useCaseId, 'useCaseId');
        }
        if (ids) {
            ids.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'ids');
            })
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsSoftwareProductionUnitPojo>(`${this.configuration.basePath}/software-production-unit`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateHardwareProductionUnit(requestParameters: UpdateHardwareProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public updateHardwareProductionUnit(requestParameters: UpdateHardwareProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public updateHardwareProductionUnit(requestParameters: UpdateHardwareProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public updateHardwareProductionUnit(requestParameters: UpdateHardwareProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateHardwareProductionUnit.');
        }
        const hardwareProductionUnitDto = requestParameters.hardwareProductionUnitDto;
        if (hardwareProductionUnitDto === null || hardwareProductionUnitDto === undefined) {
            throw new Error('Required parameter hardwareProductionUnitDto was null or undefined when calling updateHardwareProductionUnit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/software-production-unit/hardware-pu/${encodeURIComponent(String(id))}/update`,
            hardwareProductionUnitDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductionUnit(requestParameters: UpdateProductionUnitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public updateProductionUnit(requestParameters: UpdateProductionUnitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public updateProductionUnit(requestParameters: UpdateProductionUnitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public updateProductionUnit(requestParameters: UpdateProductionUnitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateProductionUnit.');
        }
        const softwareProductionUnitDto = requestParameters.softwareProductionUnitDto;
        if (softwareProductionUnitDto === null || softwareProductionUnitDto === undefined) {
            throw new Error('Required parameter softwareProductionUnitDto was null or undefined when calling updateProductionUnit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/software-production-unit/${encodeURIComponent(String(id))}/update`,
            softwareProductionUnitDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
