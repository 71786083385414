import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'excel-list-preview',
    templateUrl: './excel-list-preview.component.html',
    styleUrls: ['./excel-list-preview.component.css']
})
export class ExcelListPreviewComponent {
    @Input()
    columnsLength = 9;

    @Input()
    headerRowNumber = 1;

    @Input()
    showSerial = true;

    @Input()
    items = [];

    loading = false;
    payments: any[] = [];

    getRange(n: number): number[] {
        return Array.from({length: n}, (_, index) => index + 1);
    }
}
