<div class="table-wrapper">
  <div *ngIf="!loading" class="table-responsive mt-4">
    <ng-container>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" *ngIf="showSerial">S/N</th>
            <th scope="col" *ngFor="let i of getRange(columnsLength); index as index">{{items[headerRowNumber][index +1]}}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="true">
            <tr *ngFor="let item of items.slice(headerRowNumber + 1, items.length); index as i">
              <td *ngIf="showSerial">{{ i + 1 }}</td>
              <td *ngFor="let i of getRange(columnsLength); index as index">
                {{ item[index + 1] }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>
