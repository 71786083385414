import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AcceptanceCriteriaControllerService } from './api/acceptance-criteria-controller.service';
import { AuditTrailControllerService } from './api/audit-trail-controller.service';
import { BlockerCategoryControllerService } from './api/blocker-category-controller.service';
import { BlockerControllerService } from './api/blocker-controller.service';
import { BreachControllerService } from './api/breach-controller.service';
import { BusinessRequirementControllerService } from './api/business-requirement-controller.service';
import { BusinessRequirementDocumentControllerService } from './api/business-requirement-document-controller.service';
import { BusinessRequirementSectionControllerService } from './api/business-requirement-section-controller.service';
import { CadenceControllerService } from './api/cadence-controller.service';
import { CertificationBodyControllerService } from './api/certification-body-controller.service';
import { CertificationControllerService } from './api/certification-controller.service';
import { CiRequestControllerService } from './api/ci-request-controller.service';
import { CountryControllerService } from './api/country-controller.service';
import { CustomerValueControllerService } from './api/customer-value-controller.service';
import { CustomerValueSuccessCriteriaControllerService } from './api/customer-value-success-criteria-controller.service';
import { DashboardControllerService } from './api/dashboard-controller.service';
import { DelegationControllerService } from './api/delegation-controller.service';
import { DeploymentEnvironmentControllerService } from './api/deployment-environment-controller.service';
import { DesignationControllerService } from './api/designation-controller.service';
import { EmailValidationControllerService } from './api/email-validation-controller.service';
import { EpicControllerService } from './api/epic-controller.service';
import { ExecutionControllerService } from './api/execution-controller.service';
import { FileControllerService } from './api/file-controller.service';
import { FinancialCostControllerService } from './api/financial-cost-controller.service';
import { IssueCategoryControllerService } from './api/issue-category-controller.service';
import { KeyResultControllerService } from './api/key-result-controller.service';
import { LagMeasureControllerService } from './api/lag-measure-controller.service';
import { LeadMeasureControllerService } from './api/lead-measure-controller.service';
import { ModuleControllerService } from './api/module-controller.service';
import { NonCompletionConsequenceControllerService } from './api/non-completion-consequence-controller.service';
import { OtpControllerService } from './api/otp-controller.service';
import { PlatformControllerService } from './api/platform-controller.service';
import { PlayerStatisticsControllerService } from './api/player-statistics-controller.service';
import { ProcessControllerService } from './api/process-controller.service';
import { ProductionProcessComplianceControllerService } from './api/production-process-compliance-controller.service';
import { ProductionProcessStageControllerService } from './api/production-process-stage-controller.service';
import { ProductionReleaseControllerService } from './api/production-release-controller.service';
import { ProductionUnitControllerService } from './api/production-unit-controller.service';
import { ProductionUnitLifecycleControllerService } from './api/production-unit-lifecycle-controller.service';
import { RequirementSpecificationParameterControllerService } from './api/requirement-specification-parameter-controller.service';
import { ResourceVerificationControllerService } from './api/resource-verification-controller.service';
import { ScoreboardControllerService } from './api/scoreboard-controller.service';
import { SkillControllerService } from './api/skill-controller.service';
import { SoftwareDeploymentEnvironmentControllerService } from './api/software-deployment-environment-controller.service';
import { SoftwareModuleTestCaseControllerService } from './api/software-module-test-case-controller.service';
import { StateControllerService } from './api/state-controller.service';
import { TicketControllerService } from './api/ticket-controller.service';
import { UiControllerService } from './api/ui-controller.service';
import { UseCaseControllerService } from './api/use-case-controller.service';
import { UserControllerService } from './api/user-controller.service';
import { UserManagementControllerService } from './api/user-management-controller.service';
import { UserStoryControllerService } from './api/user-story-controller.service';
import { WigControllerService } from './api/wig-controller.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
