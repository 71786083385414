/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { QueryResultsFinancialCostPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface DownloadFinancialCostExcelTemplateRequestParams {
    year: number;
    month?: number;
}

export interface SearchFinancialCostRequestParams {
    identifier?: string;
    amount?: number;
    lossGain?: 'GAIN' | 'LOSS';
    breachCategoryCode?: string;
    reviewedAt?: string;
    description?: string;
    breachCostPercentage?: number;
    type?: 'PROCESS_BREACH_DEDUCTION' | 'DIRECT_ENERGY_WASTE';
    reviewedBy?: string;
    algorithmCode?: string;
    portalAccount?: string;
    iso4217CurrencyCode?: string;
    createdAt?: string;
    processBreachLog?: string;
    createdBy?: string;
    reviewStatus?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    id?: number;
    playerDailyRate?: number;
    effectiveDate?: string;
    player?: string;
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    limit?: number;
    createdBefore?: string;
    keyword?: string;
    createdAfter?: string;
    priorityItems?: Array<object>;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class FinancialCostControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFinancialCostExcelTemplate(requestParameters: DownloadFinancialCostExcelTemplateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public downloadFinancialCostExcelTemplate(requestParameters: DownloadFinancialCostExcelTemplateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public downloadFinancialCostExcelTemplate(requestParameters: DownloadFinancialCostExcelTemplateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public downloadFinancialCostExcelTemplate(requestParameters: DownloadFinancialCostExcelTemplateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const year = requestParameters.year;
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling downloadFinancialCostExcelTemplate.');
        }
        const month = requestParameters.month;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (month !== undefined && month !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>month, 'month');
        }
        if (year !== undefined && year !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>year, 'year');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/financial-cost/download-financial-cost-excel-template`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Financial Cost
     * Filter Financial Cost
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchFinancialCost(requestParameters: SearchFinancialCostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsFinancialCostPojo>;
    public searchFinancialCost(requestParameters: SearchFinancialCostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsFinancialCostPojo>>;
    public searchFinancialCost(requestParameters: SearchFinancialCostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsFinancialCostPojo>>;
    public searchFinancialCost(requestParameters: SearchFinancialCostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const identifier = requestParameters.identifier;
        const amount = requestParameters.amount;
        const lossGain = requestParameters.lossGain;
        const breachCategoryCode = requestParameters.breachCategoryCode;
        const reviewedAt = requestParameters.reviewedAt;
        const description = requestParameters.description;
        const breachCostPercentage = requestParameters.breachCostPercentage;
        const type = requestParameters.type;
        const reviewedBy = requestParameters.reviewedBy;
        const algorithmCode = requestParameters.algorithmCode;
        const portalAccount = requestParameters.portalAccount;
        const iso4217CurrencyCode = requestParameters.iso4217CurrencyCode;
        const createdAt = requestParameters.createdAt;
        const processBreachLog = requestParameters.processBreachLog;
        const createdBy = requestParameters.createdBy;
        const reviewStatus = requestParameters.reviewStatus;
        const id = requestParameters.id;
        const playerDailyRate = requestParameters.playerDailyRate;
        const effectiveDate = requestParameters.effectiveDate;
        const player = requestParameters.player;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const createdBefore = requestParameters.createdBefore;
        const keyword = requestParameters.keyword;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (amount !== undefined && amount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>amount, 'amount');
        }
        if (lossGain !== undefined && lossGain !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lossGain, 'lossGain');
        }
        if (breachCategoryCode !== undefined && breachCategoryCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>breachCategoryCode, 'breachCategoryCode');
        }
        if (reviewedAt !== undefined && reviewedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewedAt, 'reviewedAt');
        }
        if (description !== undefined && description !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>description, 'description');
        }
        if (breachCostPercentage !== undefined && breachCostPercentage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>breachCostPercentage, 'breachCostPercentage');
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }
        if (reviewedBy !== undefined && reviewedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewedBy, 'reviewedBy');
        }
        if (algorithmCode !== undefined && algorithmCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>algorithmCode, 'algorithmCode');
        }
        if (portalAccount !== undefined && portalAccount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccount, 'portalAccount');
        }
        if (iso4217CurrencyCode !== undefined && iso4217CurrencyCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iso4217CurrencyCode, 'iso4217CurrencyCode');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (processBreachLog !== undefined && processBreachLog !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>processBreachLog, 'processBreachLog');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (reviewStatus !== undefined && reviewStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewStatus, 'reviewStatus');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (playerDailyRate !== undefined && playerDailyRate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerDailyRate, 'playerDailyRate');
        }
        if (effectiveDate !== undefined && effectiveDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>effectiveDate, 'effectiveDate');
        }
        if (player !== undefined && player !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>player, 'player');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsFinancialCostPojo>(`${this.configuration.basePath}/financial-cost`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
