import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AlertType} from "../../../../extranet/report-issue/report-issue.component";
import {environment} from "../../../../../../environments/environment";

@Component({
    selector: 'app-attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent {

    public files: any[] = [];

    @Input()
    required = false;

    @Input()
    maxNumberOfDocForUpload = 5

    @Input()
    title = 'Supporting Document';

    @Input()
    maxDocSize: number = 5 * 1024 * 1024

    @Output()
    alertChange: EventEmitter<{ message: string, alertType: AlertType }> = new EventEmitter<{message: string; alertType: AlertType}>();

    @Input()
    accepts ='application/pdf, image/jpg, image/jpeg, image/png';

    @Input()
    acceptsText = 'PNG'

    isFileUploaded  = false;

    @Input()
    previousLogoId: number;
    @Input()
    viewOnly = false;

    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            if (this.files.length === this.maxNumberOfDocForUpload) {

                this.alertChange.emit({
                    message:
                        `You can upload a maximum of ${this.maxNumberOfDocForUpload} document`,
                    alertType: AlertType.error
                })

                return
            }

            if (item.size > this.maxDocSize) {
                this.alertChange.emit({
                    message:
                        `Maximum document size is ${this.maxDocSize / (1024 * 1024)}MB`,
                    alertType: AlertType.error
                })

                continue;
            }
            const totalSize = 0

            if ((item.size + totalSize) > this.maxDocSize) {
                this.alertChange.emit({
                    message:
                        `Maximum size of all documents must not be greater than
                    ${this.maxDocSize / (1024 * 1024)}MB`,
                    alertType: AlertType.error
                })

                return
            }
            console.log('type: ', this.getFileType(item));
            if (this.getFileType(item) == 'pdf') {
                if (item) {
                    const reader = new FileReader();
                    reader.readAsDataURL(item);
                    reader.onload = (): void => {
                        const base64String = reader?.result?.toString().split(',')[1];
                        const docs = this.files;
                        docs.push({
                            base64Data: base64String,
                            type: "pdf",
                            fileName: this.getFileName(item)
                        });
                        this.files = [...docs];
                        console.log(this.files);
                    };
                }
                return;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(item);
                reader.onload = (): void => {
                    const base64 = reader.result as string;
                    const docs = this.files;
                    docs.push({
                        base64Data: base64,
                        type: "image",
                        fileName: this.getFileName(item)
                    });
                    this.files = [...docs];
                    console.log(this.files);
                };
            }
        }
    }

    getFileType(file: File): any {
        return file?.name?.split('.')?.pop();
    }

    getFileName(file: File): string {
        return file.name;
    }


    delete(file: any): void {
        this.isFileUploaded = false;
        this.files.splice(this.files.indexOf(file), 1);
    }


    onSelect(event: { addedFiles: any; }) {
        this.isFileUploaded = true;
        this.prepareFilesList(event.addedFiles)
    }

    preview(file: any) {
        this.download(file);
/*        const dataUrl = 'data:application/pdf;base64,' + file.base64Data;
        const downloadLink = document.createElement('a');
        downloadLink.style.display = 'none';
        downloadLink.href = dataUrl;
        downloadLink.setAttribute('download', 'Attachment');
        downloadLink.setAttribute('target', '_blank'); // Open in a new tab
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);*/

    }

    download(file: any){
        let dataUrl: string;

        if (file?.bwBinaryDataId) {
            dataUrl = this.getFileUrl(file?.bwBinaryDataId, true)

        } else {
            dataUrl = 'data:application/pdf;base64,' + file.base64Data;
        }
        const downloadLink = document.createElement('a');
        downloadLink.style.display = 'none';
        downloadLink.href = dataUrl;
        downloadLink.setAttribute('download', `Attachment`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink)
    }

    getFileUrl(fileId: number, download = true): string {
        return download
            ? `${environment.apiBaseUrl}/files/${fileId}`
            : `${environment.apiBaseUrl}/files/${fileId}/open`;
    }

    imageUrl(id: number) : string {
        return `${environment.siteUrl}/files/${id}`;
    }

}
