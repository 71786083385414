<div class="dash-content p-0">
  <div class="container">
    <div class="row vh-100">
      <div class="col-md-8 px-3">
        <div class="py-md-5">

          <div class="d-flex align-items-center mb-4">
            <div  class="d-flex align-items-center pointer" [routerLink] = "'/dashboard/tickets'">
              <div class="p-1 px-2 me-2" style="background: #d8e0ff; border-radius: 4px">
                <span class="iconify" data-icon="basil:caret-left-solid"></span>
              </div>
              <p class="mb-0 fs-14">Go back</p>
            </div>
          </div>
          <!--<div class="mb-4 pb-3">
            <h3 class="mb-0">{{ customer?.name }}</h3>
            &lt;!&ndash;            <p>Product Name: <span class="mx-3">{{ticket?.platform?.name}}</span></p>&ndash;&gt;
          </div>-->
            <div class="mb-4 pb-3">
                <div class="card customer-details">
                    <div class="card-body">
                        <h4 class="fw-bolder">
                            Customer Details
                        </h4>
                        <table style="border-collapse: collapse;">
                            <tr>
                                <td>Name:</td>
                                <td>
                                    <p>{{ customer?.name }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Phone Number:</td>
                                <td>
                                    <p>{{ customer?.phoneNumber || '-' }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>
                                    <p>{{ customer?.email }}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

          <div class="my-5"></div>

          <div>
            <h5>Ticket Description</h5>
          </div>

          <div class="mt-3 px-3 w-100 rounded-4 py-md-5 px-md-4">
            <form [formGroup]="form">
              <textarea
                formControlName="description"
                name=""
                id="product-description"
                rows="15"
                class="form-control w-100"
                placeholder="Ticket description"
              ></textarea>
            </form>
          </div>

          <!--<div class="mt-3 px-3 w-100 rounded-4 py-md-4 px-md-4">
            <div class="w-50">
              <div class="row">
                <div class="col-md-4">
                  <div style="cursor: pointer" class="border rounded mx-2 py-1">
                    <span
                      class="iconify mx-2"
                      data-icon="fa-solid:reply"
                      data-inline="false"
                    ></span
                    ><span class="mx-1">Reply</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div style="cursor: pointer" class="border rounded mx-1 py-1">
                    <span
                      class="iconify mx-1"
                      data-icon="fa-solid:arrow-circle-right"
                      data-inline="false"
                    ></span
                    ><span class="mx-1">Forward</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div style="cursor: pointer" class="border rounded mx-1 py-1">
                    <span
                      class="iconify mx-1"
                      data-icon="fa-solid:sticky-note"
                      data-inline="false"
                    ></span>
                    <span class="mx-1">Add Notes</span>
                  </div>
                </div>
              </div>
            </div>
          </div>-->

        </div>
      </div>
      <div
        class="col-md-4"
        style="
          border-left: 1px solid #888888;
          height: 100vh;
          padding-left: 10px;
        "
      >
        <div class="py-md-5">
          <h4 class="fs-16 mb-3">Ticket Details</h4>

          <div class="card bg-body">
            <div class="card-body p-3" style="position: relative">
              <div style="position: absolute; top: 0; right: 0">
                <a
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#addUser"
                  data-bs-backdrop="static"
                  aria-controls="addUser"
                >
                  <span
                    class="iconify"
                    data-icon="fa-solid:edit"
                    data-inline="false"
                    style="font-size: 1.5em;"
                  ></span>
                </a>
              </div>

              <div>
                <div>
                  <p class="fw-700">
                    Ticket Id:
                    <span class="mx-3">{{ ticket?.identifier }}</span>
                  </p>
                  <p class="fw-700">
                    Status: <span class="mx-3">{{ status }}</span>
                  </p>
                  <p class="fw-700">
                    Priority:
                    <span class="mx-3">{{
                      ticket?.issuePriority | underscoreToSpace
                    }}</span>
                  </p>
                  <p class="fw-700">
                    Date Logged:
                    <span class="mx-3">{{ ticket?.createdAt | datetime }}</span>
                  </p>
                  <!--<p class="fw-700">
                    Reporter:
                    <span class="mx-3">{{ customer?.name | titlecase }}</span>
                  </p>-->
                  <p class="fw-700">
                    Assigned To:
                    <span class="mx-3">{{
                      details?.assignedTo
                        ? details?.assignedTo?.name
                        : "Unassigned"
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="py-md-5">
          <h4 class="fs-16 mb-3">
            {{ ticket?.assignedTo ? "Re-assign ticket " : "Assign ticket" }}
          </h4>

          <div class="card bg-body">
            <div class="card-body">
              <div
                *ngIf="showErrorMessageTrigger && isAssignment"
                class="alert alert-danger mt-4 container"
              >
                <div class="d-flex mb-2">
                  <span
                    class="iconify me-2"
                    data-icon="clarity:warning-alt-filled"
                    style="font-size: 24px"
                  ></span>
                  {{ getErrorMessage() }}
                </div>
              </div>
              <div
                *ngIf="showSuccessMessageTrigger && isAssignment"
                [ngClass]="{ anim: showSuccessMessageTrigger }"
                class="d-custom-none alert alert-success my-4 fs-14 text-center"
              >
                {{ getSuccessMessage() | titlecase }}
              </div>
              <div>
                <form [formGroup]="assignForm">
                  <label class="form-label required">
                    {{
                      ticket?.assignedTo ? "Re-assign to " : "Assign to"
                    }}</label
                  >
                  <div class="custom-select">
                    <select
                      name=""
                      class="form-control"
                      formControlName="assign"
                    >
                      <option [value]="''">-- Select Individual --</option>
                      <option [value]="r.id" *ngFor="let r of representatives">
                        {{ r.name | titlecase }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>

<!--              <div-->
<!--                *ngIf="!edit"-->
<!--                class="d-flex justify-content-end align-items-end mb-3 mt-4 p-0"-->
<!--              >-->
<!--                <button (click)="editDetails()" class="btn btn-primary">-->
<!--                  Edit-->
<!--                </button>-->
<!--              </div>-->
              <div
                class="d-flex justify-content-end align-items-end mb-3 mt-4 p-0"
              >
                <button [disabled]="!enable" (click)="assign()" class="btn btn-primary">Assign</button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end align-items-end mb-3 mt-4 p-0">
          <button (click)="goToTicketDetail(ticketId)" class="btn btn-primary">View lifecycle status</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="offcanvas offcanvas-end"
  tabindex="-1"
  data-bs-backdrop="static"
  id="addUser"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header border-bottom border-1 justify-content-end">
    <button
      #closeTicketEdit
      type="button"
      class="close-btn btn-primary"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    >
      <span class="nav-title">Cancel</span>
    </button>
  </div>
  <div class="offcanvas-body">
    <div>
      <h4 class="fs-26 mt-3">Edit ticket details</h4>
    </div>

    <div
      *ngIf="showErrorMessageTrigger && isUpdate"
      class="alert alert-danger mt-4 container"
    >
      <div class="d-flex mb-2">
        <span
          class="iconify me-2"
          data-icon="clarity:warning-alt-filled"
          style="font-size: 24px"
        ></span>
        {{ getErrorMessage() }}
      </div>
    </div>
    <div
      *ngIf="showSuccessMessageTrigger && isUpdate"
      [ngClass]="{ anim: showSuccessMessageTrigger }"
      class="d-custom-none alert alert-success my-4 fs-14 text-center"
    >
      {{ getSuccessMessage() | titlecase }}
    </div>

    <div class="d-flex flex-column">
      <div class="row g-3">
        <form [formGroup]="editForm">
          <div class="mb-3">
            <label class="form-label required">Status</label>
            <div class="input-group">
              <select
                name=""
                id="status"
                class="form-control"
                formControlName="status"
              >
                <option [value]="''">-- Select Status --</option>
                <option
                  [value]="status.valueOf()"
                  *ngFor="let status of ticketStatus"
                >
                  {{ status | underscoreToSpace }}
                </option>
              </select>
            </div>
            <error-message
              [form]="editForm"
              [controlName]="'status'"
            ></error-message>
          </div>

          <div class="mb-3">
            <label class="form-label required">Category</label>
            <div class="input-group">
              <select
                name=""
                id="status"
                class="form-control"
                formControlName="category"
              >
                <option [value]="''">-- Select Category --</option>
                <option [value]="c.code" *ngFor="let c of ticketCategories">
                  {{ c.name | titlecase }}
                </option>
              </select>
            </div>
            <error-message
              [form]="editForm"
              [controlName]="'category'"
            ></error-message>
          </div>
          <div class="mb-3">
            <label class="form-label required">Ticket Type</label>
            <div class="input-group">
              <select
                name=""
                id="status"
                class="form-control"
                formControlName="type"
              >
                <option [value]="''">-- Select Type --</option>
                <option [value]="type" *ngFor="let type of ticketTypes">
                  {{ type | underscoreToSpace }}
                </option>
              </select>
            </div>
            <error-message
              [form]="editForm"
              [controlName]="'type'"
            ></error-message>
          </div>
          <div class="mb-3">
            <label class="form-label required">Service</label>
            <div class="input-group">
              <select
                name=""
                id="status"
                class="form-control"
                formControlName="service"
              >
                <option [value]="''">-- Select Service --</option>
                <option [value]="s.code" *ngFor="let s of service">
                  {{ s.name | underscoreToSpace }}
                </option>
              </select>
            </div>
            <error-message
              [form]="editForm"
              [controlName]="'service'"
            ></error-message>
          </div>

          <div class="mb-3">
            <label class="form-label required">Sub Service</label>
            <div class="input-group">
              <select
                name=""
                id="status"
                class="form-control"
                formControlName="subService"
              >
                <option [value]="''">-- Select Sub Service --</option>
                <option [value]="sub.code" *ngFor="let sub of subServices">
                  {{ sub.name | underscoreToSpace }}
                </option>
              </select>
            </div>
            <error-message
              [form]="editForm"
              [controlName]="'subService'"
            ></error-message>
          </div>

          <div class="mb-3">
            <label class="form-label required">Subject</label>
            <div class="input-group">
              <input
                name=""
                id="status"
                class="form-control"
                formControlName="subject"
              />
            </div>
            <error-message
              [form]="editForm"
              [controlName]="'subject'"
            ></error-message>
          </div>

          <div class="mt-5">
            <div
              class="d-flex justify-content-end align-items-end mb-3 mt-4 p-0"
            >
              <button (click)="update()" type="button" class="btn btn-primary">
                Update
                <ng-container *ngIf="!submitting">
                  <span
                    ><span
                      class="iconify icon"
                      data-icon="basil:check-outline"
                    ></span
                  ></span>
                </ng-container>
                <ng-container *ngIf="submitting">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">updating...</span>
                </ng-container>
              </button>
              <div class="mx-3"></div>
              <!-- add space between buttons -->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
