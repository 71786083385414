/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Platform } from './platform';
import { PortalUser } from './portal-user';


export interface Sprint { 
    id?: number;
    createdAt?: string;
    createdBy?: PortalUser;
    status?: Sprint.StatusEnum;
    name?: string;
    identifierDisplayName?: string;
    identifier?: string;
    plannedStartTime?: string;
    plannedEndTime?: string;
    completedAt?: string;
    completionStatus?: Sprint.CompletionStatusEnum;
    reviewStatus?: Sprint.ReviewStatusEnum;
    platform?: Platform;
}
export namespace Sprint {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type CompletionStatusEnum = 'COMPLETED' | 'NOT_COMPLETED';
    export const CompletionStatusEnum = {
        Completed: 'COMPLETED' as CompletionStatusEnum,
        NotCompleted: 'NOT_COMPLETED' as CompletionStatusEnum
    };
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        Approved: 'APPROVED' as ReviewStatusEnum,
        Pending: 'PENDING' as ReviewStatusEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusEnum
    };
}


