/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HardwareDeploymentEnvironmentDto { 
    id?: number;
    environmentType?: HardwareDeploymentEnvironmentDto.EnvironmentTypeEnum;
    totalMemoryInByte?: number;
    totalStorageInByte?: number;
    cpuCore?: number;
}
export namespace HardwareDeploymentEnvironmentDto {
    export type EnvironmentTypeEnum = 'PRODUCTION' | 'TEST' | 'DEVELOPMENT' | 'PRE_PRODUCTION';
    export const EnvironmentTypeEnum = {
        Production: 'PRODUCTION' as EnvironmentTypeEnum,
        Test: 'TEST' as EnvironmentTypeEnum,
        Development: 'DEVELOPMENT' as EnvironmentTypeEnum,
        PreProduction: 'PRE_PRODUCTION' as EnvironmentTypeEnum
    };
}


