import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './pages/extranet/landing-page/landing-page.component';
import { SiteFooterComponent } from './layout/site-footer/site-footer.component';
import { LoginComponent } from './pages/extranet/login/login.component';
import { DashboardComponent } from './pages/intranet/dashboard/dashboard.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "./shared/shared.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {CountdownComponent} from "ngx-countdown";
import {KeycloakAngularModule, KeycloakEventType, KeycloakService} from "keycloak-angular";
import {AuthenticationService} from "./services/authentication.service";
import {environment} from "../environments/environment";
import {HttpInterceptorService} from "./services/http-interceptor.service";
import { PageHeaderComponent } from './layout/page-header/page-header.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {
  ApiModule,
  AuditTrailControllerService,
  Configuration,
  ConfigurationParameters
} from "../../sdk/customer-fulfillment-api-sdk";
import {TicketsModule} from "./pages/intranet/tickets/tickets.module";
import {ManageTicketsComponent} from "./pages/intranet/tickets/manage-tickets/manage-tickets.component";
import {DashboardLayoutComponent} from "./layout/dashboard-layout/dashboard-layout.component";
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { MasterRecordsComponent } from './pages/intranet/master-records/master-records.component';
import { ExtranetLayoutComponent } from './layout/extranet-layout/extranet-layout.component';
import { ExtranetHeaderComponent } from './layout/extranet-header/extranet-header.component';
import {LottieModule} from "ngx-lottie";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {filter, mergeMap, Observable} from "rxjs";
import {provideUserIdleConfig} from "angular-user-idle";
import {ForbiddenComponent} from "./pages/extranet/forbidden/forbidden.component";
import {ViewScoreboardComponent} from "./pages/intranet/execution/scoreboard/view-scoreboard/view-scoreboard.component";
import {LocaleService} from "ngx-daterangepicker-material";
import {DragProvider} from "ngx-chips/core/providers/drag-provider";


export function lottiePlayerFactory(): any {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}
export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.siteUrl,
  };
  return new Configuration(params);
}

function initializeKeycloak(keycloak: KeycloakService,
                            authenticationService: AuthenticationService,
                            loggerService: AuditTrailControllerService) {
  const STORAGE_KEY = 'isFirstLogin';
  let isFirstLogin =
      localStorage.getItem(STORAGE_KEY) === null || localStorage.getItem(STORAGE_KEY) == 'true';

  keycloak.keycloakEvents$
      .pipe(
          filter((value) => value.type === KeycloakEventType.OnAuthSuccess),
          mergeMap((value) => {
            return authenticationService
                .fetchUser()
                .toPromise()
                .then((res) => {
                  if (isFirstLogin) {
                    isFirstLogin = false;
                    localStorage.setItem(STORAGE_KEY, 'false');
                    return loggerService.logLoginAction({ auditTrailDto: {} }).subscribe();
                  } else {
                    return new Observable();
                  }
                });
          })
      ).subscribe();
  return () =>
    keycloak.init({
      config: {...environment.keycloakConfig},
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
        redirectUri: environment.keycloakConfig.url
      }
    });
}
// AoT requires an exported function for factories
/**
 *
 * @param http
 */
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    SiteFooterComponent,
    LoginComponent,
    DashboardComponent,
    PageHeaderComponent,
    ManageTicketsComponent,
    DashboardLayoutComponent,
    SideNavComponent,
    ExtranetLayoutComponent,
    ExtranetHeaderComponent,
    MasterRecordsComponent,
    ForbiddenComponent
  ],
  imports: [
    BrowserModule,
    LottieModule.forRoot({player: lottiePlayerFactory}),
    AppRoutingModule,
    ReactiveFormsModule,
    KeycloakAngularModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CountdownComponent,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ApiModule.forRoot(apiConfigFactory),
    NgMultiSelectDropDownModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthenticationService, AuditTrailControllerService]
    },
    provideUserIdleConfig({ idle: environment.sessionTimeout, timeout: 5, ping: 5 }),
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

