import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {FormHelper} from "../../../models/etc/form-helper";
import {
    CodingStandardSearchComponent
} from "../../../pages/intranet/process/components/coding-standard-search/coding-standard-search.component";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-compliance-disapproval',
    templateUrl: './compliance-disapproval.component.html',
    styleUrls: ['./compliance-disapproval.component.css']
})
export class ComplianceDisapprovalComponent {

    form = this.fb.group({
        reason: ['',
            Validators.compose([
                Validators.required,
                Validators.minLength(FormHelper.NOTE_FIELD_MIN_LENGTH),
                Validators.maxLength(FormHelper.NOTE_FIELD_MAX_LENGTH),
                Validators.pattern(FormHelper.ALPHANUMERIC_WITH_HYPHEN_HASH_DOT_SLASH_UNDERSCORE_PIPE_SPACE_NOT_CONSECUTIVE_COMMA_AMPERSAND_BRACES_COLON)
            ])],
    })

    @Input()
    reasonLabel = 'Reason';

    @Input()
    showCodingStandardCheck = true;

    @Output()
    onConfirmClicked = new EventEmitter<void>();

    @ViewChild("codingStandardSearchComponent")
    codingStandardSearchComponent: CodingStandardSearchComponent;

    @Input()
    productionStageCode = environment.productionStandardProcessCode;

    constructor(private fb: FormBuilder) {
    }

    protected readonly FormHelper = FormHelper;

}
