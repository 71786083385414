import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { mergeMap, Observable, of } from 'rxjs';
import { User } from '../../models/user/user.model';
import { AuthenticationService } from '../../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard  {
  constructor(
    protected readonly authenticationService: AuthenticationService,
    protected readonly router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authenticationService.getUser().pipe(
      mergeMap((user: User) => {
        if (user == null) {
          this.router.navigate(['/dashboard']);
          return of(false);
        }

        if (user.hasRole('ADMIN')) {
          return of(true);
        }
        this.router.navigate(['/dashboard']);
        return of(false);
      })
    );
  }
}
